/**
 * Created by Terence on 2024/6/27 - 15:21
 * Description :
 */
import { ref } from "vue";

const title = ref("");

const setTitle = (val: string) => {
  title.value = val;
  document.title = val;
};

export function useTitle() {
  return {
    title,
    setTitle,
  };
}
