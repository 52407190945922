import dayjs from "dayjs";
import { storageKeys } from "@/constant/storage";

let lastTokenSetTime = null;

export function shouldRefreshToken() {
  const now = dayjs().valueOf();
  const expireTime = localStorage.getItem(storageKeys.TOKEN_EXPIRE_AT);

  if (expireTime && +expireTime < now + 30 * 1000) {
    return true;
  }
  return false;
}

export function updateTokenSetTime() {
  lastTokenSetTime = Date.now();
}

export function setStorageToken(token: string) {
  localStorage.setItem(storageKeys.token, token);
  if (token) {
    updateTokenSetTime();
  }
}

export function getStorageToken() {
  return localStorage.getItem(storageKeys.token);
}

export function removeStorageToken() {
  localStorage.removeItem(storageKeys.token);
}

export function setRefreshToken(refreshToken: string) {
  localStorage.setItem(storageKeys.REFRESH_TOKEN, refreshToken);
}

export function getRefreshToken() {
  return localStorage.getItem(storageKeys.REFRESH_TOKEN);
}

export function removeRefreshToken() {
  localStorage.removeItem(storageKeys.REFRESH_TOKEN);
}

export function setExpireTimestamp(interval: number) {
  if (interval === -1) return;
  const expireTime = dayjs().valueOf() + interval;
  localStorage.setItem(storageKeys.TOKEN_EXPIRE_AT, expireTime.toString());
}

export function setCookieToken(token: string) {
  const days = 730;
  const expires: any = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  if (token) {
    document.cookie = `LINJIE_TOKEN=${token}; domain=linjieapp.com; expires=${expires.toGMTString()};`;
  }
}
