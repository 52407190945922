import { createApp } from "vue";
import { ConfigProvider } from "vant";
import { createPinia } from "pinia";
import { isDev } from "@/utils/env-related";
import App from "./App.vue";
// import { pigeonInterceptor } from "@frontend/pigeon/dist/browser.min.js";
import "./font/index.scss";
import router from "./router";
import "./sensors";

import "vant/lib/index.css";
import "@frontend/du-styles/styles/index.scss";
// import "@frontend/dumpling/dist/style.css";
//
import "dangoui/dist/mp/style.css";
import "dangoui/dist/prefix/px/style.css";
import "dangoui/dist/theme.css";

import { globalConfig } from "dangoui";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import jb from "@frontend/effuse";

// import "./utils/ljBridge";

jb.initFlutter();

if (isDev) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const VConsole = require("vconsole");
  new VConsole();
}

const pinia = createPinia();

const app = createApp(App);

app.use(globalConfig, {
  themes: [
    {
      name: "linjie",
      colors: {
        primary: {
          button: {
            solidBg: "linear-gradient(151deg, #2B1AC0 0%, #7247DC 100%)",
          },
        },
      },
    },
  ],
});

app.use(router).use(ConfigProvider).use(pinia).mount("#app");
