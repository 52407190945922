import sensors from "sa-sdk-javascript";

const env =
  process.env.NODE_ENV === "production" ? "production" : "development";

console.log("env!!!!", env);

export function getServerUrl() {
  if (env === "development") {
    return "https://sensors-api.qiandao.cn/sa?project=default";
  }
  return "https://sensors-api.qiandao.cn/sa?project=production";
}

sensors.init({
  server_url: getServerUrl(),
  show_log: process.env.NODE_ENV === "development",
  heatmap: {
    clickmap: "not_collect",
    scroll_notice_map: "not_collect",
  },
});

sensors.registerPage({
  package_id: 1045,
});

sensors.quick("autoTrack", {
  platform: "h5",
});

export default sensors;
