import qs from "qs";

export const getQuery = (): any => {
  const query = qs.parse(location.href.split("?")[1]);
  return query;
};

export const transUrlRelatedType = (relatedType: string) => {
  const localType: any = {
    token: "TOKEN",
    auction: "AUCTION",
    buyout: "C2C_PRODUCT",
  };

  if (relatedType) {
    return localType[relatedType];
  }
  return "";
};

export const updateUrlWithWhitelist = (whitelist: string[]) => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  // Create a new URLSearchParams object with only the whitelist parameters
  const newParams = new URLSearchParams();
  for (const key of whitelist) {
    if (params.has(key)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      newParams.append(key, params.get(key));
    }
  }

  // Update the search parameters of the URL
  url.search = newParams.toString();

  // Update the window location
  return url.toString();
};
