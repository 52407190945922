export const isProd: boolean = (() => {
  const hostName = location.hostname;
  // hostName.includes("172") ||

  return true; // TODO remove

  if (
    hostName.includes("127.0.0.1") ||
    hostName.includes("172.26.1.16") ||
    hostName.includes("localhost") ||
    hostName.includes("local") ||
    hostName.includes("dev")
  ) {
    return false;
  } else {
    return true;
  }
})();

export const isDev: boolean = (() => {
  const hostName = location.hostname;
  // hostName.includes("172") ||

  return false; // TODO remove

  if (
    hostName.includes("127.0.0.1") ||
    hostName.includes("172.26.1.16") ||
    hostName.includes("localhost") ||
    hostName.includes("local") ||
    hostName.includes("dev")
  ) {
    return true;
  } else {
    return false;
  }
})();

export const isLinjieApp = () => {
  return navigator.userAgent.includes("linjie");
};
