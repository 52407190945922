<template>
  <!--  <van-config-provider theme="dark"  :theme-vars="themeVars">-->
  <van-config-provider>
    <van-sticky>
      <van-nav-bar
        v-if="route.meta && route.meta.useDefaultNav && isShowNavBar"
        :title="title"
        left-arrow
        @click-left="onClickLeft"
      >
        <!--        <template #left>-->
        <!--          <van-image-->
        <!--            fit="cover"-->
        <!--            class="back-img"-->
        <!--            src="https://cdn.qiandaoapp.com/ued/dd361cf3cdc244a1dd1feca5fc4468f7.png!mfit_w480_h480_jpg"-->
        <!--            alt=""-->
        <!--          />-->
        <!--        </template>-->
      </van-nav-bar>
    </van-sticky>

    <!--    dev环境才展示的login-->
    <login-btn />
    <router-view />
  </van-config-provider>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import { NavBar as VanNavBar, Sticky as VanSticky } from "vant";
import { useRoute, useRouter } from "vue-router";
import { getQuery } from "@/utils/url";
import LoginBtn from "@/components/LoginBtn/index.vue";
import { useTitle } from "@/hooks/useTitle";

const router = useRouter();
const route = useRoute();

const { title } = useTitle();
const isShowNavBar = ref(true);

// 保存原始的document.title设置方法
// const originalSetTitle = document.title;

// 覆盖document.title的设置方法
// Object.defineProperty(document, "title", {
//   set(value) {
//     title.value = value; // 更新响应式引用
//     originalSetTitle?.call?.(document, value); // 调用原始方法设置标题
//   },
//   get() {
//     return originalSetTitle?.call?.(document);
//   },
// });

const onClickLeft = () => {
  router.back();
};

onMounted(() => {
  const { hideNavBar } = getQuery();
  if (hideNavBar === "YES") {
    isShowNavBar.value = false;
  }
});

onUnmounted(() => {
  // Object.defineProperty(document, "title", {
  //   set: originalSetTitle,
  // });
});

// watch(title, (newValue, oldValue) => {
//   console.log(`标题从 "${oldValue}" 变更为 "${newValue}"`);
// });

// const themeVars = {
// buttonPrimaryBackground: 'linear-gradient(150.58deg, #2B1AC0 0%, #7247DC 100%)',
// buttonDefaultFontSize: px2rem(32),
// buttonBorderWidth: px2rem(0),
// buttonRadius: px2rem(16),
// checkboxSize: px2rem(28),
// checkboxCheckedIconColor: '#2B1AC0',
// };
</script>

<style lang="scss">
:root:root {
  .BACKGROUND_LINEAR_PURPLE {
    //max-width: 100vw;
    //touch-action: none;
    background: linear-gradient(151deg, #2b1ac0 0%, #7247dc 100%) !important;
    //background-color: red !important;
    .van-popup {
      max-width: 100vw;
      img {
        max-width: 100%;
        zoom: 1;
        touch-action: none;
      }
    }
    .van-swipe-item {
      img {
        transform: scale(0.4) !important;
      }
    }
  }
  //body {
  //  zoom: 1;
  //  -moz-text-size-adjust: 100%;
  //  -ms-text-size-adjust: 100%;
  //  -webkit-text-size-adjust: 100%;
  //}
  //--van-nav-bar-background: transparent;

  // 禁止图片长按保存
  .van-image__img,
  .van-image-preview__image,
  img,
  .magnifier-img,
  .head-title-cover-img {
    -webkit-touch-callout: none; /* 禁止弹出菜单 */
    -webkit-user-select: none; /* 禁止选中文本 */
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
  }
  .downloadable-img-wrp {
    .van-image__img,
    .van-image-preview__image,
    img,
    .magnifier-img,
    .head-title-cover-img {
      -webkit-touch-callout: unset !important; /* 禁止弹出菜单 */
      -webkit-user-select: unset !important; /* 禁止选中文本 */
      -khtml-user-select: unset !important;
      -moz-user-select: unset !important;
      -ms-user-select: unset !important;
      user-select: unset !important;
      pointer-events: unset !important;
    }
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

body {
  padding: 0;
}

.mobLink-wrapper {
  display: none;
}

.back-img {
  width: 88rpx;
  height: 88rpx;
}

.otext {
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.otext2 {
  display: -webkit-box;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.u-flex-c-sb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.u-flex-c {
  display: flex;
  align-items: center;
}
//:root {
//  --van-white: red;
//  --van-blue: red;
//  --van-button-primary-color: var(--van-white);
//  --van-button-primary-background: var(--van-primary-color);
//}
</style>
