/**
 * Created by Terence on 2023/2/20 - 18:57
 * Description :
 */
import { defineStore } from "pinia";
import { storageKeys } from "@/constant/storage";
import { shouldRefreshToken } from "@/utils/authorize";

// 第一个参数是应用程序中 store 的唯一 id
export const useUsersStore = defineStore("user", {
  // 其它配置项
  state: () => {
    return {
      userInfo: JSON.parse(localStorage.getItem(storageKeys.USER_INFO) || "{}"),
      expireTime: JSON.parse(
        localStorage.getItem(storageKeys.TOKEN_EXPIRE_AT) || "{}"
      ),
      name: "小猪课堂",
      age: 25,
      sex: "男",
    };
  },
  getters: {
    // getAddAge: (state) => {
    //   return state.age + 100;
    // },
    getIsLogin(state) {
      // console.log('shouldRefreshToken===', shouldRefreshToken);
      return state.userInfo?.userId && !shouldRefreshToken();
    },
    // 可以这么使用 store.getAddAge(1100)
    getAddAge: (state) => {
      return (num: number) => state.age + num;
    },
    getNameAndAge(): string {
      return this.name + this.getAddAge; // 调用其它getter
    },
  },
  actions: {
    // 可以这么使用 store.saveName("我是小猪");
    saveName(name: string) {
      this.name = name;
    },
    handleLoginIfNot() {
      if (this.getIsLogin) return;
      window.location.href = `${location.origin}/login`;
    },
    setUserInfo(userInfo: any) {
      this.userInfo = userInfo;
      localStorage.setItem(storageKeys.USER_INFO, JSON.stringify(userInfo));
    },
  },
});
